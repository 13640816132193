import { useSelector } from 'react-redux'

import {
  getAllCatalogs,
  getCatalogBrandState,
  getCatalogTitle,
  getConfigurationState,
  getData,
  getDataDtos,
  getDynamicFilters,
  getDynamicFiltersUi,
  getFilters,
  getFiltersChangeCount,
  getFilterSearchQuery,
  getSearchSession,
  getSelectedDefaultDynamicFilters,
  getSelectedDynamicFilters,
  getTemporaryCatalogIds,
  getTemporaryPriceRange,
  getTemporarySelectedDynamicFilters,
} from 'state/catalog-filters/selectors'

import useFeatureSwitch from 'hooks/useFeatureSwitch/useFeatureSwitch'

import { CatalogState } from './types'

import useCatalogContext from './useCatalogContext'

const useCatalogState = (): CatalogState => {
  const isCatalogContextEnabled = useFeatureSwitch('catalog_context_state')
  const { state } = useCatalogContext()

  const reduxState: CatalogState = {
    filters: useSelector(getFilters),
    dynamicFilters: {
      dynamicFilters: useSelector(getDynamicFilters),
      selectedDynamicFilters: useSelector(getSelectedDynamicFilters),
      selectedDefaultFilters: useSelector(getSelectedDefaultDynamicFilters),
      temporarySelectedDynamicFilters: useSelector(getTemporarySelectedDynamicFilters),
      temporaryCatalogIds: useSelector(getTemporaryCatalogIds),
      temporaryPriceRange: useSelector(getTemporaryPriceRange),
      filterSearchQuery: useSelector(getFilterSearchQuery),
      ui: useSelector(getDynamicFiltersUi),
    },
    dtos: useSelector(getDataDtos),
    data: {
      catalogs: useSelector(getAllCatalogs),
      uiState: useSelector(getData).uiState,
    },
    configuration: useSelector(getConfigurationState),
    catalogBrand: useSelector(getCatalogBrandState),
    searchSession: useSelector(getSearchSession),
    general: {
      title: useSelector(getCatalogTitle),
      filtersChangeCount: useSelector(getFiltersChangeCount),
    },
  }

  return isCatalogContextEnabled ? state : reduxState
}

export default useCatalogState
