import { useRef } from 'react'
import { useSelector } from 'react-redux'

import clientSideMetrics from 'libs/common/client-side-metrics'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { MS_PER_SECOND } from 'constants/date'
import { AdPage, AdPlatform, AdShape } from 'constants/ads'

type Props = {
  countryCode: string
  shape: AdShape | 'Rokt'
  page: AdPage
  platform: AdPlatform
}

function useAdLoadtimeLogging({ countryCode, shape, page, platform }: Props) {
  const isAdsLoadTimeClientSideMetricsEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ads_load_time_client_side_metrics'),
  )

  const adRequestTimestampRef = useRef(0)

  const onRequest = () => {
    if (!isAdsLoadTimeClientSideMetricsEnabled) return

    adRequestTimestampRef.current = Date.now()
  }

  const onLoad = () => {
    if (!adRequestTimestampRef.current || !isAdsLoadTimeClientSideMetricsEnabled) return

    const adLoadTime = Date.now() - adRequestTimestampRef.current

    clientSideMetrics
      .histogram('ad_load', {
        country_code: countryCode,
        shape,
        page,
        platform,
      })
      .observe(adLoadTime / MS_PER_SECOND)
  }

  return { onRequest, onLoad }
}

export default useAdLoadtimeLogging
