'use client'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Text, Icon, Button } from '@vinted/web-ui'
import { Bookmark24, BookmarkFilled24 } from '@vinted/monochrome-icons'

import { toggleSearchSubscriptionEvent } from 'libs/common/event-tracker/events'

import { getCatalogItemErrors } from 'state/items/selectors'
import {
  getGlobalSearchSessionId,
  getIsPopularInCatalog,
  getQuery,
  getSearchSessionId,
} from 'state/catalog-filters/selectors'

import { Screen } from 'constants/tracking/screens'
import useTracking from 'hooks/useTracking/useTracking'
import useBreakpoint from 'hooks/useBreakpoint'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession/useSession'
import { SearchSubscribeModal } from 'components/SavedSearches'
import { useSavedSearchesContext } from 'components/Header/SavedSearchesProvider'
import useCatalogState from 'pages/Catalog/CatalogProvider/useCatalogState'
import { filtersToApiParams } from 'components/Header/SavedSearchesProvider/transformers'

import useSavedSearchesSubscribeEducation from '../SearchSubscribeModal/useSavedSearchesSubscribeEducation'

const SearchSubscribeButton = () => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const translate = useTranslate()

  const query = useSelector(getQuery)
  const errors = useSelector(getCatalogItemErrors)
  const isPopularCatalog = useSelector(getIsPopularInCatalog)
  const searchSessionId = useSelector(getSearchSessionId)
  const globalSearchSessionId = useSelector(getGlobalSearchSessionId)
  const userId = useSession().user?.id
  const {
    dynamicFilters: { selectedDynamicFilters },
    filters,
  } = useCatalogState()

  const [isLoading, setIsLoading] = useState(false)

  const { currentSearch, actions } = useSavedSearchesContext()

  const {
    showSearchSubscriptionEducation,
    closeSearchSubscriptionEducation,
    isSubscribedModalOpen,
  } = useSavedSearchesSubscribeEducation()

  const toggleSubscription = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isLoading) {
      event.preventDefault()

      return
    }

    if (!userId) return

    setIsLoading(true)

    actions
      .toggleCurrentSearchSubscription(filtersToApiParams(filters, selectedDynamicFilters))
      .finally(() => setIsLoading(false))

    if (!currentSearch?.subscribed) {
      showSearchSubscriptionEducation()
    }

    track(
      toggleSearchSubscriptionEvent({
        savedRecentSearchSessionId: null,
        listName: null,
        savedRecentSearchListId: null,
        screen: Screen.Catalog,
        isSubscribing: !currentSearch?.subscribed,
        searchSessionId,
        searchTitle: currentSearch?.title,
        searchQuery: currentSearch?.search_text ?? query,
        globalSearchSessionId,
      }),
    )
  }

  if (errors || isPopularCatalog || !userId) return null

  const action = currentSearch?.subscribed ? 'delete' : 'save'
  const text = <FormattedMessage id={`saved_searches.actions.${action}`} />

  const a11yLabel = breakpoints.phones ? translate(`saved_searches.a11y.${action}`) : undefined

  return (
    <>
      <SearchSubscribeModal
        isOpen={isSubscribedModalOpen}
        onClose={closeSearchSubscriptionEducation}
      />

      <Button
        isLoading={isLoading}
        text={
          !breakpoints.phones && (
            <Text type={Text.Type.Subtitle} theme="amplified" as="span">
              {text}
            </Text>
          )
        }
        icon={
          <Icon
            name={currentSearch?.subscribed ? BookmarkFilled24 : Bookmark24}
            color={currentSearch?.subscribed ? Icon.Color.Primary : Icon.Color.GreyscaleLevel1}
          />
        }
        onClick={toggleSubscription}
        aria={{
          'aria-label': a11yLabel,
        }}
        testId={`catalog-search-${action}-button`}
        theme="muted"
      />
    </>
  )
}

export default SearchSubscribeButton
