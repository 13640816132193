'use client'

import { MouseEvent, MutableRefObject, useEffect, useRef } from 'react'
import { Cell, Text } from '@vinted/web-ui'
import { escape, escapeRegExp, isEqual, kebabCase, noop } from 'lodash'
import { InView } from 'react-intersection-observer'

import List from 'components/List'
import useTracking from 'hooks/useTracking'

import { SearchSuggestionType } from 'constants/search'
import {
  viewSearchSuggestionEvent,
  ViewSearchSuggestionEventArgs,
  viewSearchSuggestionsEvent,
} from 'libs/common/event-tracker/events'
import { SearchSuggestionModel } from 'types/models'
import useLatestCallback from 'hooks/useLatestCallback'
import useFeatureSwitch from 'hooks/useFeatureSwitch/useFeatureSwitch'

type Props = {
  query: string
  items: Array<SearchSuggestionModel>
  highlightedIndex: number | null
  searchSessionId: string
  globalSearchSessionId: string | null
  suggestionUrl: (suggestion: SearchSuggestionModel) => string | undefined
  suggestionsListId: MutableRefObject<string | null>
  suggestionsSessionId: MutableRefObject<string>
  onSuggestionClick?: (
    suggestion: SearchSuggestionModel,
    index: number,
    filterSuggestionCount: number,
    event: MouseEvent,
  ) => void
  seenSuggestions: MutableRefObject<Set<number>>
  onClearSuggestions: () => void
}

const SearchSuggestions = ({
  query,
  items,
  highlightedIndex,
  searchSessionId,
  globalSearchSessionId,
  suggestionUrl,
  suggestionsListId,
  suggestionsSessionId,
  seenSuggestions,
  onClearSuggestions,
  onSuggestionClick = noop,
}: Props) => {
  const { track } = useTracking()

  const prevItems = useRef<Array<SearchSuggestionModel>>()
  const trackedQuery = useRef(query)

  const shouldAddViewSearchSuggestionEvent = useFeatureSwitch(
    'web_add_view_search_suggestion_event',
  )

  useEffect(() => {
    trackedQuery.current = query
  }, [query])

  useEffect(() => {
    if (!items.length || isEqual(prevItems.current, items)) return

    track(
      viewSearchSuggestionsEvent({
        suggestions: items.map(item => item.query),
        query: trackedQuery.current,
        suggestionsSessionId: suggestionsSessionId.current,
      }),
    )

    prevItems.current = items
  }, [track, items, suggestionsSessionId])

  const onClearSuggestionsLatest = useLatestCallback(onClearSuggestions)

  useEffect(() => onClearSuggestionsLatest, [onClearSuggestionsLatest])

  const handleItemClick =
    (suggestion: SearchSuggestionModel, index: number) => (event: MouseEvent) => {
      const scopedSuggestions = items.filter(item => item.type === SearchSuggestionType.Scoped)

      onSuggestionClick(suggestion, index, scopedSuggestions.length, event)
    }

  const getSuggestionKey = (suggestion: SearchSuggestionModel) => {
    if (suggestion.type === SearchSuggestionType.Scoped) {
      return [suggestion.id, kebabCase(suggestion.subtitle)].join('-')
    }

    return suggestion.id
  }

  const getHighlightedTitle = (suggestionTitle: string) => {
    const escapedQuery = escape(query)
    const escapedSuggestionTitle = escape(suggestionTitle)

    return escapedSuggestionTitle.replace(
      new RegExp(`^${escapeRegExp(escapedQuery)}`, 'i'),
      '<b>$&</b>',
    )
  }

  const renderSuggestion = (suggestion: SearchSuggestionModel, index: number) => {
    const cellProps = {
      type: Cell.Type.Navigating,
      url: suggestionUrl(suggestion),
      highlighted: highlightedIndex === index,
      testId: `suggestion-${suggestion.id}`,
    }

    switch (suggestion.type) {
      case SearchSuggestionType.Scoped:
        return (
          <Cell
            {...cellProps}
            onClick={handleItemClick(suggestion, index)}
            key={getSuggestionKey(suggestion)}
          >
            <Text
              theme="amplified"
              text={getHighlightedTitle(suggestion.title)}
              html
              highlight
              highlightTheme="muted"
            />{' '}
            <Text text={suggestion.subtitle} type={Text.Type.Subtitle} theme="primary" as="span" />
          </Cell>
        )
      case SearchSuggestionType.Multiword:
      case SearchSuggestionType.Brand:
      case SearchSuggestionType.Catalog: {
        return (
          <Cell
            {...cellProps}
            onClick={handleItemClick(suggestion, index)}
            key={getSuggestionKey(suggestion)}
          >
            <Text
              theme="amplified"
              text={getHighlightedTitle(suggestion.title)}
              html
              highlight
              highlightTheme="muted"
            />
          </Cell>
        )
      }
      case SearchSuggestionType.Fallback:
        return (
          <Cell
            {...cellProps}
            onClick={handleItemClick(suggestion, index)}
            title={suggestion.title}
            key={getSuggestionKey(suggestion)}
          />
        )
      default:
        return null
    }
  }

  const getHandleItemView = (item: SearchSuggestionModel, index: number) => (inView: boolean) => {
    if (!inView) return

    if (seenSuggestions.current.has(item.id)) return
    seenSuggestions.current.add(item.id)

    const isFrontendGeneratedTextSelected = item.type === SearchSuggestionType.Fallback
    const trackingArgs: ViewSearchSuggestionEventArgs = {
      frontendGeneratedTextSelected: isFrontendGeneratedTextSelected,
      globalSearchSessionId,
      query: item.query,
      searchSessionId: searchSessionId || null,
      selectedSuggestionText: isFrontendGeneratedTextSelected ? item.query : item.title,
      suggestionPosition: index + 1,
      suggestionsListId: suggestionsListId.current,
      suggestionsSessionId: suggestionsSessionId.current,
    }

    if ('params' in item) {
      trackingArgs.params = item.params
    }

    track(viewSearchSuggestionEvent(trackingArgs))
  }

  const renderSuggestions = (suggestions: Array<SearchSuggestionModel>) => (
    <List>
      {suggestions.map((suggestion, index) => (
        <InView onChange={getHandleItemView(suggestion, index)} key={getSuggestionKey(suggestion)}>
          {renderSuggestion(suggestion, index)}
        </InView>
      ))}
    </List>
  )

  if (!items.length) return null

  return (
    <Cell styling={Cell.Styling.Tight}>
      {shouldAddViewSearchSuggestionEvent ? (
        renderSuggestions(items)
      ) : (
        <List>{items.map((item, index) => renderSuggestion(item, index))}</List>
      )}
    </Cell>
  )
}

export default SearchSuggestions
