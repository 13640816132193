'use client'

import Script from 'next/script'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CDN_ADS_URL } from 'constants/index'

import { getIsCookieConsentVersion } from 'state/consent/selectors'
import { getPlacements } from 'state/ads/selectors'

import { AdKind } from 'constants/ads'

import { initPrebidSetup, setPrebidConsentManagement } from './utils'

const Prebid = () => {
  const isCookieConsentVersion = useSelector(getIsCookieConsentVersion)
  const adsPlacements = useSelector(getPlacements)

  const adagioParams = useMemo(() => {
    const adPlacement = adsPlacements.find(placement => placement.kind === AdKind.Rtb)

    if (!adPlacement || adPlacement.kind !== AdKind.Rtb) return undefined

    return adPlacement.bids?.find(bid => bid.bidder === 'adagio')?.params
  }, [adsPlacements])

  useEffect(() => {
    initPrebidSetup(adagioParams)
  }, [adagioParams])

  useEffect(() => {
    setPrebidConsentManagement(isCookieConsentVersion)
  }, [isCookieConsentVersion])

  return (
    <Script
      id="prebid-script"
      data-testid="prebid-script"
      src={`${CDN_ADS_URL}/prebid.js`}
      strategy="lazyOnload"
    />
  )
}

export default Prebid
