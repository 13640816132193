'use client'

import { ReactNode, useEffect, useState } from 'react'
import { Tooltip } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'
import { useSavedSearchesContext, SelectedSection } from 'components/Header/SavedSearchesProvider'

const SHOW_TOOLTIP_MS = 4e3
const TOOLTIP_SHOWN_COUNT_KEY = 'saved_searches:tooltip_shown_count_v2'
const MAX_SHOW_COUNT = '3'

type Props = {
  children: ReactNode
}

const SavedSearchTooltip = ({ children }: Props) => {
  const translate = useTranslate('saved_searches.separation.tooltip')

  const { selectedSection, subscribedSearches } = useSavedSearchesContext()

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (getLocalStorageItem(TOOLTIP_SHOWN_COUNT_KEY) === MAX_SHOW_COUNT) return undefined

    setShow(true)

    const timeout = setTimeout(() => {
      setShow(false)
    }, SHOW_TOOLTIP_MS)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (!show) return

    const currentCount = getLocalStorageItem(TOOLTIP_SHOWN_COUNT_KEY)
    if (!currentCount) {
      setLocalStorageItem(TOOLTIP_SHOWN_COUNT_KEY, '1')

      return
    }

    const updatedCount = Number(currentCount) + 1

    setLocalStorageItem(TOOLTIP_SHOWN_COUNT_KEY, `${updatedCount}`)
  }, [show])

  useEffect(() => {
    if (selectedSection !== SelectedSection.Subscribed) return

    setLocalStorageItem(TOOLTIP_SHOWN_COUNT_KEY, MAX_SHOW_COUNT)
    setShow(false)
  }, [selectedSection])

  const onClick = () => setShow(false)

  const content =
    subscribedSearches.length === 0
      ? translate('zero_searches')
      : translate('at_least_one_searches')

  return (
    <Tooltip
      shiftOptions={{}}
      testId="subscribed-search-tooltip"
      onClick={onClick}
      content={content}
      strategy="fixed"
      placement={Tooltip.Placement.BottomLeft}
      show={show}
      hover={false}
    >
      {children}
    </Tooltip>
  )
}

export default SavedSearchTooltip
