import { createContext } from 'react'

import { UiState } from 'constants/ui'

import {
  DtoState,
  CatalogState,
  DynamicFiltersState,
  FilterState,
  DataState,
  ConfigurationState,
  CatalogBrandState,
  SearchSessionState,
  GeneralState,
} from './types'
import { CatalogAction } from './reducers/actionTypes'

export const initialFilterState: FilterState = {
  query: null,
  catalogIds: [],
  priceFrom: null,
  priceTo: null,
  currency: null,
  sortBy: null,
  isPopularCatalog: false,
  isPersonalizationDisabled: false,
  catalogFrom: null,
  disableSearchSaving: false,
}

export const initialDynamicFilterState: DynamicFiltersState = {
  dynamicFilters: [],
  selectedDynamicFilters: [],
  selectedDefaultFilters: [],
  temporarySelectedDynamicFilters: [],
  temporaryCatalogIds: [],
  temporaryPriceRange: {
    priceFrom: null,
    priceTo: null,
  },
  filterSearchQuery: {
    text: null,
    code: null,
  },
  ui: {
    uiState: UiState.Idle,
    errors: [],
  },
}

export const initialDtoState: DtoState = {
  catalogs: [],
  dynamicFilters: [],
  selectedDynamicFilters: [],
  selectedDefaultFilters: [],
}

export const initialDataState: DataState = {
  catalogs: [],
  uiState: UiState.Pending,
}

export const initialConfigurationState: ConfigurationState = {
  config: {
    currency: '',
    locale: '',
  },
  ui: {
    uiState: UiState.Idle,
    errors: [],
  },
}

export const initialCatalogBrandState: CatalogBrandState = {
  brand: undefined,
}

export const initialSearchSessionState: SearchSessionState = {
  correlationId: '',
  sessionId: '',
  globalSearchSessionId: null,
  isStale: false,
}

export const initialGeneralState: GeneralState = {
  filtersChangeCount: 0,
  title: null,
}

export const initialState: CatalogState = {
  filters: initialFilterState,
  dynamicFilters: initialDynamicFilterState,
  dtos: initialDtoState,
  data: initialDataState,
  configuration: initialConfigurationState,
  catalogBrand: initialCatalogBrandState,
  searchSession: initialSearchSessionState,
  general: initialGeneralState,
}

type CatalogContextType = {
  state: CatalogState
  dispatch: React.Dispatch<CatalogAction>
}

const CatalogContext = createContext<CatalogContextType>({
  state: initialState,
  dispatch: () => null,
})

export default CatalogContext
