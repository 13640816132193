import type { FullScreenMessage, InAppMessage, ModalMessage } from '@braze/web-sdk'

import { NotificationPosition } from 'types/components'

export enum InAppModalMessageType {
  Splash = 'Splash',
  Cover = 'Cover',
  FullScreenSplash = 'FullScreenSplash',
}

export enum InAppMessageDismissMethod {
  CloseButton = 'x_button',
  BackgroundClick = 'background_click',
  NoUrlButton = 'no_url_button',
  AutoDismiss = 'auto_dismiss',
}

export type InAppMessageTrackingDataModel = {
  campaignName: string | undefined
  campaignMessageName: string | undefined
  canvasName: string | undefined
  canvasVariantName: string | undefined
}

export type InAppModalMessageModel = {
  header: string | undefined
  duration: number | undefined
  message: string
  primaryButtonText: string | undefined
  secondaryButtonText: string | undefined
  primaryButtonUrl: string | undefined
  secondaryButtonUrl: string | undefined
  imageUrl: string
  videoURL: string | undefined // TODO: change casing to videoUrl
  type: InAppModalMessageType
  shouldOpenLinkInNewTab: boolean
  original: ModalMessage | FullScreenMessage
} & InAppMessageTrackingDataModel

export type InAppNotificationMessageModel = {
  message: string
  duration: number | undefined
  imageUrl: string | undefined
  position: NotificationPosition
  url: string | undefined
  shouldOpenLinkInNewTab: boolean
  original: InAppMessage
} & InAppMessageTrackingDataModel
