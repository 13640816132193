import { Dispatch, SetStateAction, createContext } from 'react'

import {
  CreateSavedSearchArgs,
  GetSavedSearchArgs,
  SavedSearchApiParams,
  UpdateSavedSearchArgs,
} from 'types/api'
import { SavedSearchDto } from 'types/dtos'

import { SelectedSection } from './constants'

export type SavedSearchesContextType = {
  searches: Array<SavedSearchDto>
  subscribedSearches: Array<SavedSearchDto>
  selectedSearches: Array<SavedSearchDto>
  currentSearch: SavedSearchDto | undefined
  selectedSection: SelectedSection
  currentSearchId: number | undefined
  actions: {
    setSelectedSection: Dispatch<SetStateAction<SelectedSection>>
    createSearch: (arg: CreateSavedSearchArgs) => Promise<void>
    fetchSearch: (arg: GetSavedSearchArgs) => Promise<SavedSearchDto | undefined>
    fetchSubscribedSearches: () => Promise<void>
    toggleCurrentSearchSubscription: (arg: SavedSearchApiParams) => Promise<void>
    fetchSearches: () => Promise<void>
    toggleSearchSubscription: (searchId: number) => Promise<void>
    updateSearch: (arg: UpdateSavedSearchArgs) => Promise<void>
  }
}

const noop = () => Promise.resolve()

export const savedSearchesContextDefault: SavedSearchesContextType = {
  subscribedSearches: [],
  searches: [],
  currentSearch: undefined,
  selectedSection: SelectedSection.Recent,
  currentSearchId: undefined,
  selectedSearches: [],
  actions: {
    setSelectedSection: noop,
    fetchSearches: noop,
    createSearch: noop,
    fetchSearch: () => Promise.resolve(undefined),
    fetchSubscribedSearches: noop,
    toggleCurrentSearchSubscription: noop,
    toggleSearchSubscription: noop,
    updateSearch: noop,
  },
}

const SavedSearchesContext = createContext<SavedSearchesContextType>(savedSearchesContextDefault)

export default SavedSearchesContext
